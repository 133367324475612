import { HttpResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatPaginator } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { CityEditComponent } from '../../../../components/settings/city/city-edit/city-edit.component';
import { environment } from '../../../../../environments/environment';
import {
  DeleteDialogComponent,
  PulpoSnackbarComponent,
  clearPossibleSubscriptions,
  compare,
} from '@pulpo/pulpo-commons';
import { CityService } from '@pulpo/pulpo-api';
import { ICity } from '@pulpo/pulpo-models';

@Component({
  selector: 'app-city-list',
  templateUrl: './city-list.component.html',
  styleUrls: ['./city-list.component.scss'],
})
export class CityListComponent implements OnInit, OnDestroy {
  cities: ICity[];
  filteredCities: ICity[];
  citySource: MatTableDataSource<ICity>;
  displayedColumns: string[];
  itemsPerPage: number;
  private citySubscription: Subscription;
  private cityEditSubscription: Subscription;
  private cityDeleteSubscription: Subscription;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  currentSort: Sort;

  constructor(
    private cityService: CityService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.displayedColumns = ['id', 'name', 'country', 'options'];
    this.itemsPerPage = environment.itemsPerPage;

    this.loadCities();
  }

  ngOnDestroy(): void {
    clearPossibleSubscriptions(
      this.citySubscription,
      this.cityEditSubscription,
      this.cityDeleteSubscription
    );
  }

  private loadCities(): void {
    this.citySubscription = this.cityService.query().subscribe({
      next: (res: HttpResponse<ICity[]>) => {
        this.cities = res.body || [];
        this.filteredCities = [...this.cities];
        this.sortCities();
      },
      error: (err: Error) => {
        alert(err.message);
      },
    });
  }

  sortCities(sort: Sort = { active: 'name', direction: 'asc' }): void {
    if (!sort.direction) {
      sort = { active: 'name', direction: 'asc' };
    }

    this.currentSort = sort;

    const isAsc = sort.direction === 'asc';

    if (sort.active !== 'country') {
      this.filteredCities.sort((a, b) => {
        return compare((a as any)[sort.active], (b as any)[sort.active], isAsc);
      });
    } else {
      this.filteredCities
        .sort((a: any, b: any) => {
          return compare(a.country?.codeCountry, b.country?.codeCountry, isAsc);
        })
        .sort((a: any, b: any) => {
          return compare(a.country?.name, b.country?.name, isAsc);
        });
    }

    this.setDisplayedCities();
  }

  private setDisplayedCities() {
    this.citySource = new MatTableDataSource(this.filteredCities);
    this.citySource.paginator = this.paginator;
  }

  filterCities(filteredCities: ICity[]) {
    this.filteredCities = filteredCities;
    this.sortCities(this.currentSort);
  }

  openCityEditDialog(id?: number): void {
    const dialogRef = this.dialog.open(CityEditComponent, {
      disableClose: false,
      data: { city: this.cities.find((c) => c.id === id) },
      width: '850px',
      height: '250px',
    });

    clearPossibleSubscriptions(this.cityEditSubscription);

    this.cityEditSubscription = dialogRef.afterClosed().subscribe({
      next: (result: ICity) => {
        if (result) {
          if (id) {
            const city: ICity | undefined = this.cities.find(
              (c) => c.id === id
            );
            for (const key in result) {
              (city as any)[key] = (result as any)[key];
            }
          } else {
            this.cities.push(result);
          }
          this.snackBar.openFromComponent(PulpoSnackbarComponent, {
            data: {
              title: 'Modification de la ville',
              message:
                'La ville ' +
                result.name +
                ' (' +
                result.id +
                ')' +
                ' a bien été ' +
                (id ? 'modifiée' : 'créée') +
                '.',
            },
            duration: 4000,
            panelClass: ['snackbar'],
          });
          this.cityService.citiesChangedSubject.next(this.cities);
        }
      },
      error: (err) => {
        console.error(err);
      },
    });
  }

  openCityDeleteDialog(city: ICity): void {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      disableClose: false,
      data: {
        id: city.id,
        content:
          'Voulez-vous supprimer la ville suivante : ' +
          city.name +
          ' (' +
          city.id +
          ') ? ',
      },
      height: '30%',
    });

    clearPossibleSubscriptions(this.cityDeleteSubscription);

    this.cityDeleteSubscription = dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result && result !== -1) {
          this.cityService.delete(city.id).subscribe({
            next: () => {
              this.cities.splice(this.cities.indexOf(city), 1);
              this.snackBar.openFromComponent(PulpoSnackbarComponent, {
                data: {
                  title: 'Modification de la ville',
                  message:
                    'La ville ' +
                    city.name +
                    ' (' +
                    city.id +
                    ')' +
                    ' a bien été supprimée.',
                },
                duration: 4000,
                panelClass: ['snackbar'],
              });
              this.cityService.citiesChangedSubject.next(this.cities);
            },
            error: (err) => {
              console.error(err);
            },
          });
        }
      },
      error: (err) => {
        console.error(err);
      },
    });
  }
}
