import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Sort } from '@angular/material/sort';
import { HttpResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AirportEditComponent } from '../airport-edit/airport-edit.component';
import { environment } from '../../../../../environments/environment';
import {
  DeleteDialogComponent,
  PulpoSnackbarComponent,
  clearPossibleSubscriptions,
  compare,
} from '@pulpo/pulpo-commons';
import { AirportService } from '@pulpo/pulpo-api';
import { IAirport } from '@pulpo/pulpo-models';

@Component({
  selector: 'app-airport-list',
  templateUrl: './airport-list.component.html',
  styleUrls: ['./airport-list.component.scss'],
})
export class AirportListComponent implements OnInit, OnDestroy {
  airports: IAirport[];
  filteredAirports: IAirport[];
  airportSource: MatTableDataSource<IAirport>;
  displayedColumns: string[];
  itemsPerPage: number;
  private airportSubscription: Subscription;
  private airportEditSubscription: Subscription;
  private airportDeleteSubscription: Subscription;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  currentSort: Sort;

  constructor(
    private airportService: AirportService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.displayedColumns = [
      'id',
      'iata',
      'name',
      'city',
      'country',
      'options',
    ];
    this.itemsPerPage = environment.itemsPerPage;

    this.loadAirports();
  }

  ngOnDestroy(): void {
    clearPossibleSubscriptions(
      this.airportSubscription,
      this.airportEditSubscription,
      this.airportDeleteSubscription
    );
  }

  private loadAirports(): void {
    this.airportSubscription = this.airportService.query().subscribe({
      next: (res: HttpResponse<IAirport[]>) => {
        this.airports = res.body || [];
        this.filteredAirports = [...this.airports];
        this.sortAirports();
      },
      error: (err: Error) => {
        alert(err.message);
      },
    });
  }

  sortAirports(sort: Sort = { active: 'name', direction: 'asc' }) {
    if (!sort.direction) {
      sort = { active: 'name', direction: 'asc' };
    }

    this.currentSort = sort;

    const isAsc = sort.direction === 'asc';

    this.filteredAirports.sort((a: any, b: any) => {
      switch (sort.active) {
        case 'city':
          return compare(a.city?.name, b.city?.name, isAsc);
        case 'country':
          return compare(a.city?.country?.name, b.city?.country?.name, isAsc);
        default:
          return compare(
            (a as any)[sort.active],
            (b as any)[sort.active],
            isAsc
          );
      }
    });

    this.setDisplayedAirports();
  }

  private setDisplayedAirports() {
    this.airportSource = new MatTableDataSource(this.filteredAirports);
    this.airportSource.paginator = this.paginator;
  }

  filterAirports(filteredAirports: IAirport[]) {
    this.filteredAirports = filteredAirports;
    this.sortAirports(this.currentSort);
  }

  openAirportEditDialog(id?: number): void {
    const dialogRef = this.dialog.open(AirportEditComponent, {
      disableClose: false,
      data: { airport: this.airports.find((c) => c.id === id) },
      width: '850px',
      height: '250px',
    });

    clearPossibleSubscriptions(this.airportEditSubscription);

    this.airportEditSubscription = dialogRef.afterClosed().subscribe({
      next: (result: IAirport) => {
        if (result) {
          if (id) {
            const airport: IAirport | undefined = this.airports.find(
              (a) => a.id === id
            );
            for (const key in result) {
              (airport as any)[key] = (result as any)[key];
            }
          } else {
            this.airports.push(result);
          }
          this.snackBar.openFromComponent(PulpoSnackbarComponent, {
            data: {
              title: "Modification de l'aéroport",
              message:
                "L'aéroport " +
                result.name +
                ' (' +
                result.id +
                ')' +
                ' a bien été ' +
                (id ? 'modifié' : 'créé') +
                '.',
            },
            duration: 4000,
            panelClass: ['snackbar'],
          });

          this.airportService.parametersChangedSubject.next(this.airports);
        }
      },
      error: (err) => {
        console.error(err);
      },
    });
  }

  openAirportDeleteDialog(airport: IAirport): void {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      disableClose: false,
      data: {
        id: airport.id,
        content:
          "Voulez-vous supprimer l'aéroport suivant : " +
          airport.name +
          ' (' +
          airport.id +
          ') ? ',
      },
      height: '30%',
    });

    clearPossibleSubscriptions(this.airportDeleteSubscription);

    this.airportDeleteSubscription = dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result && result !== -1) {
          this.airportService.delete(airport.id as number).subscribe({
            next: () => {
              this.airports.splice(this.airports.indexOf(airport), 1);
              this.snackBar.openFromComponent(PulpoSnackbarComponent, {
                data: {
                  title: "Modification de l'aéroport",
                  message:
                    "L'aéroport " +
                    airport.name +
                    ' (' +
                    airport.id +
                    ')' +
                    ' a bien été supprimé.',
                },
                duration: 4000,
                panelClass: ['snackbar'],
              });
              this.airportService.parametersChangedSubject.next(this.airports);
            },
            error: (err) => {
              console.error(err);
            },
          });
        }
      },
      error: (err) => {
        console.error(err);
      },
    });
  }
}
