import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Sort } from '@angular/material/sort';
import { HttpResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AirlineEditComponent } from '../airline-edit/airline-edit.component';
import { environment } from '../../../../../environments/environment';
import {
  DeleteDialogComponent,
  PulpoSnackbarComponent,
  clearPossibleSubscriptions,
  compare,
} from '@pulpo/pulpo-commons';
import { AirlineService } from '@pulpo/pulpo-api';
import { IAirline } from '@pulpo/pulpo-models';

@Component({
  selector: 'app-airline-list',
  templateUrl: './airline-list.component.html',
  styleUrls: ['./airline-list.component.scss'],
})
export class AirlineListComponent implements OnInit, OnDestroy {
  airlines: IAirline[];
  filteredAirlines: IAirline[];
  airlineSource: MatTableDataSource<IAirline>;
  displayedColumns: string[];
  itemsPerPage: number;
  private airlineSubscription: Subscription;
  private airlineEditSubscription: Subscription;
  private airlineDeleteSubscription: Subscription;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  currentSort: Sort;

  constructor(
    private airlineService: AirlineService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.displayedColumns = ['id', 'iata', 'name', 'options'];
    this.itemsPerPage = environment.itemsPerPage;

    this.loadAirlines();
  }

  ngOnDestroy(): void {
    clearPossibleSubscriptions(
      this.airlineSubscription,
      this.airlineEditSubscription,
      this.airlineDeleteSubscription
    );
  }

  private loadAirlines(): void {
    this.airlineSubscription = this.airlineService.query().subscribe({
      next: (res: HttpResponse<IAirline[]>) => {
        this.airlines = res.body || [];
        this.filteredAirlines = [...this.airlines];
        this.sortAirlines();
      },
      error: (err: Error) => {
        alert(err.message);
      },
    });
  }

  sortAirlines(sort: Sort = { active: 'name', direction: 'asc' }) {
    if (!sort.direction) {
      sort = { active: 'name', direction: 'asc' };
    }

    this.currentSort = sort;

    const isAsc = sort.direction === 'asc';

    this.filteredAirlines.sort((a, b) => {
      switch (sort.active) {
        default:
          return compare(
            (a as any)[sort.active],
            (b as any)[sort.active],
            isAsc
          );
      }
    });

    this.setDisplayedAirlines();
  }

  private setDisplayedAirlines() {
    this.airlineSource = new MatTableDataSource(this.filteredAirlines);
    this.airlineSource.paginator = this.paginator;
  }

  filterAirlines(filteredAirlines: IAirline[]) {
    this.filteredAirlines = filteredAirlines;
    this.sortAirlines(this.currentSort);
  }

  openAirlineEditDialog(id?: number): void {
    const dialogRef = this.dialog.open(AirlineEditComponent, {
      disableClose: false,
      data: { airline: this.airlines.find((c) => c.id === id) },
      width: '850px',
      height: '250px',
    });

    clearPossibleSubscriptions(this.airlineEditSubscription);

    this.airlineEditSubscription = dialogRef.afterClosed().subscribe({
      next: (result: IAirline) => {
        if (result) {
          if (id) {
            const airline: IAirline | undefined = this.airlines.find(
              (a) => a.id === id
            );
            for (const key in result) {
              (airline as any)[key] = (result as any)[key];
            }
          } else {
            this.airlines.push(result);
          }
          this.snackBar.openFromComponent(PulpoSnackbarComponent, {
            data: {
              title: 'Modification de la companie aérienne',
              message:
                'La compagnie aérienne ' +
                result.name +
                ' (' +
                result.id +
                ')' +
                ' a bien été ' +
                (id ? 'modifié' : 'créé') +
                '.',
            },
            duration: 4000,
            panelClass: ['snackbar'],
          });

          this.airlineService.airlinesChangedSubject.next(this.airlines);
        }
      },
      error: (err) => {
        console.error(err);
      },
    });
  }

  openAirlineDeleteDialog(airline: IAirline): void {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      disableClose: false,
      data: {
        id: airline.id,
        content:
          'Voulez-vous supprimer la compagnie aérienne suivante : ' +
          airline.name +
          ' (' +
          airline.id +
          ') ? ',
      },
      height: '30%',
    });

    clearPossibleSubscriptions(this.airlineDeleteSubscription);

    this.airlineDeleteSubscription = dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result && result !== -1) {
          this.airlineService.delete(airline.id as number).subscribe({
            next: () => {
              this.airlines.splice(this.airlines.indexOf(airline), 1);
              this.snackBar.openFromComponent(PulpoSnackbarComponent, {
                data: {
                  title: 'Modification de la companie aérienne',
                  message:
                    'La compagnie aérienne ' +
                    airline.name +
                    ' (' +
                    airline.id +
                    ')' +
                    ' a bien été supprimé.',
                },
                duration: 4000,
                panelClass: ['snackbar'],
              });
              this.airlineService.airlinesChangedSubject.next(this.airlines);
            },
            error: (err) => {
              console.error(err);
            },
          });
        }
      },
      error: (err) => {
        console.error(err);
      },
    });
  }
}
